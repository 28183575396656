const launchDarklyOptions = { streaming: false }

const pagesThatDoNotNeedAuth = ['/register', '/recoverPasswordEmail', '/recoverPasswordEmail/confirmation', '/changePassword']

const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
const emojiRegex = /([\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{1F1E6}-\u{1F1FF}]|[\u{1F3FB}-\u{1F3FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{2300}-\u{23FF}]|[\u{1F004}]|[\u{1F0A0}-\u{1F0FF}]|[\u{1F100}-\u{1F1FF}]|[\u{1F200}-\u{1F2FF}]|[\u{3200}-\u{32FF}]|\u{200D}|\u{2B50}\u{FE0F}|\u{303D}\u{FE0F}|\u{2B06}\u{FE0F}|\u{2197}\u{FE0F}|\u{2198}\u{FE0F}|\u{2B07}\u{FE0F}|\u{2199}\u{FE0F}|\u{2B05}\u{FE0F}|\u{2196}\u{FE0F}|\u{2195}\u{FE0F}|\u{2194}\u{FE0F}|\u{21A9}\u{FE0F}|\u{21AA}\u{FE0F}|\u{2934}\u{FE0F}|\u{2935}\u{FE0F}|\u{0023}\u{FE0F}\u{20E3}|\u{002A}\u{FE0F}\u{20E3}|\u{0030}\u{FE0F}\u{20E3}|\u{0031}\u{FE0F}\u{20E3}|\u{0032}\u{FE0F}\u{20E3}|\u{0033}\u{FE0F}\u{20E3}|\u{0034}\u{FE0F}\u{20E3}|\u{0035}\u{FE0F}\u{20E3}|\u{0036}\u{FE0F}\u{20E3}|\u{0037}\u{FE0F}\u{20E3}|\u{0038}\u{FE0F}\u{20E3}|\u{0039}\u{FE0F}\u{20E3}|\u{2139}\u{FE0F}|\u{24C2}\u{FE0F}|\u{2B1B}\u{FE0F}|\u{2B1C}\u{FE0F}|\u{3030}\u{FE0F})/gu

const invalidEmailErrorMessage = 'Please input a valid email address'
const invalidPhoneErrorMessage = 'Please input a valid phone number. Letters or special characters are not allowed'

export { launchDarklyOptions, pagesThatDoNotNeedAuth, emailRegex, invalidEmailErrorMessage, invalidPhoneErrorMessage, emojiRegex }
